<template>
    <template v-if="$slots.detailTab">
        <slot v-if="iframeEnabled" name="detailTab"></slot>
    </template>
    <component :is="iframeComponent" v-else :ref="setIframeRef" :src="iframeSrc" @load="handleLoaded" :enabled="iframeEnabled" />
</template>

<script setup lang="ts">
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
import { computed, watch, toRef } from 'vue';

const OIframe = useAsyncComponent('o365.vue.components.Iframe.vue');
const DetailsIframe = useAsyncComponent('o365.vue.components.DetailsIframe.vue');

const props = defineProps<{
    iframeSrc?: string;
    detailTabTitle: string;
    iframeEnabled?: boolean;
}>();

let iframeComponent = OIframe
// if (props.menuTabs.gridProps.detailMessage != null) {
    // iframeComponent = DetailsIframe;
    // const detailMessage = toRef(() => props.menuTabs.gridProps.detailMessage)
    // watch(detailMessage, (newMessage, oldMessage) => {
        // if (iframeRef && iframeRef.iFrameRef && iframeRef.post) {
            // iframeRef.post(newMessage)
        // }
    // });
// }

let iframeRef = null;

function setIframeRef(value) {
    iframeRef = value;
}

function handleLoaded(e: Event) {
    if (props.iframeSrc) {
        // props.menuTabs.handleIframeLoaded(props.iframeSrc, e);
    }
}

</script>